import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import WelcomePage from "./welcome";
import CapitalsQuiz from "./quiz/capitals";
import CountriesQuiz from "./quiz/countries";

const Stack = createNativeStackNavigator();

const App = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        options={{
          headerShown: false,
        }}
        name="Welcome"
        component={WelcomePage}
      />
      <Stack.Screen
        name="Capital quiz"
        component={CapitalsQuiz}
      />
      <Stack.Screen
        name="Country quiz"
        component={CountriesQuiz}
      />
    </Stack.Navigator>
  );
};

export default App;
