import React, { useCallback } from "react";
import {
  GluestackUIProvider,
  createConfig,
  config as defaultConfig,
} from "@gluestack-ui/themed";
import { NavigationContainer } from "@react-navigation/native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { StatusBar } from "expo-status-bar";
import { useFonts } from "expo-font";
import { View } from "react-native";

import * as SplashScreen from "expo-splash-screen";
import QuizApp from "./src/pages/nav";

const config = createConfig({
  ...defaultConfig.theme,
  tokens: {
    ...defaultConfig.theme.tokens,
    fonts: {
      heading: "area600",
      body: "area400",
    },
  },
});

SplashScreen.preventAutoHideAsync();

const App = () => {
  const [fontsLoaded] = useFonts({
    area300: require("./assets/fonts/300.ttf"),
    area400: require("./assets/fonts/400.ttf"),
    area500: require("./assets/fonts/500.ttf"),
    area600: require("./assets/fonts/600.ttf"),
    area700: require("./assets/fonts/700.ttf"),
    area800: require("./assets/fonts/800.ttf"),
  });

  const onLayoutRootView = useCallback(async () => {
    if (fontsLoaded) {
      await SplashScreen.hideAsync();
    }
  }, [fontsLoaded]);

  if (!fontsLoaded) {
    return null;
  }

  return (
    <View
      onLayout={onLayoutRootView}
      style={{ flex: 1 }}
    >
      <SafeAreaProvider>
        <NavigationContainer
          documentTitle={{
            formatter: () => `Mojingo - fun emoji quiz game`,
          }}
        >
          <GluestackUIProvider
            config={config}
            colorMode="dark"
          >
            <QuizApp />
          </GluestackUIProvider>
        </NavigationContainer>
        <StatusBar style={"light"} />
      </SafeAreaProvider>
    </View>
  );
};

export default App;
