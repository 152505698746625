import PropTypes from "prop-types";
import React from "react";
import { View, ImageBackground, Image } from "react-native";

import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Button, Heading, ButtonText } from "@gluestack-ui/themed";
import { useNavigation } from "@react-navigation/native";

export const pinks = [
  "#ffe1fd",
  "#ffb1ea",
  "#ff7ed7",
  "#ff4cbf",
  "#ff1aa5",
  "#e60083",
  "#b40072",
  "#82005b",
  "#50003d",
  "#20001a",
  "#FF0092",
];

const App = () => {
  const { top, bottom } = useSafeAreaInsets();
  const navigation = useNavigation();

  return (
    <ImageBackground
      style={{ flex: 1 }}
      source={require("../../assets/gradient.png")}
    >
      <View
        style={{
          flex: 1,
          overflow: "hidden",
        }}
      >
        <View style={{ height: top }} />
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            paddingBottom: bottom,
            gap: 16,
          }}
        >
          <View style={{ flex: 1, justifyContent: "center" }}>
            <Image
              source={require("../../assets/logo.png")}
              style={{
                height: 96,
                resizeMode: "contain",
              }}
            />
          </View>
          <View style={{ padding: 36, justifyContent: "center", gap: 24 }}>
            <Heading
              sx={{
                color: "$black",
                fontSize: 24,
                fontWeight: "600",
                mb: "$6",
                textAlign: "center",
              }}
            >
              Select your quiz
            </Heading>

            <Button
              size="md"
              variant="solid"
              action="primary"
              onPress={() => {
                navigation.navigate("Capital quiz");
              }}
              sx={{
                p: "$6",
                bg: "white",
                borderRadius: "$full",
                ":hover": {
                  bg: pinks[0],
                  _text: {
                    color: pinks[4],
                  },
                },
              }}
            >
              <ButtonText
                sx={{
                  fontWeight: "$black",
                  color: "black",
                  fontFamily: "area600",
                }}
              >
                Capital quiz
              </ButtonText>
            </Button>

            <Button
              size="md"
              variant="solid"
              action="primary"
              onPress={() => {
                navigation.navigate("Country quiz");
              }}
              sx={{
                p: "$6",
                bg: "white",
                borderRadius: "$full",
                ":hover": {
                  bg: pinks[0],
                  _text: {
                    color: pinks[4],
                  },
                },
              }}
            >
              <ButtonText
                sx={{
                  fontWeight: "$black",
                  color: "black",
                  fontFamily: "area600",
                }}
              >
                Country quiz
              </ButtonText>
            </Button>
          </View>
        </View>
      </View>
    </ImageBackground>
  );
};

App.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func,
  }),
};

export default App;
